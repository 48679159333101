import { KendoGridWithSearchBoxAndDateFilterInitializer } from
    "./KendoGridWithSearchBoxAndDateFilterInitializer";

import { KendoGridWithSearchBoxInitializer } from
    "./KendoGridWithSearchBoxInitializer";

export function init(
    gridName: string,
    hasSearchFilter: boolean,
    hasDateFilter: boolean,
    searchFilterBoxName: string,
    dateFilterFieldName: string,
    dateFilterBoxName: string
) {
    let grid: KendoGridWithSearchBoxInitializer;

    if (hasSearchFilter && hasDateFilter) {
        console.log("initializing with grid search and date");
        grid = new KendoGridWithSearchBoxAndDateFilterInitializer(
            dateFilterBoxName,
            dateFilterFieldName,
            searchFilterBoxName,
            gridName
        );
    } else if(hasSearchFilter) {
        console.log("initializing with grid search");
        grid = new KendoGridWithSearchBoxInitializer(
            searchFilterBoxName,
            gridName
        );
    }
    
}